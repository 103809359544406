/**
 * @file System.tsx
 * @description This file contains not that much for now.
 * @author Sebastiaan Koedam
 */

import '../styles/pages/system.css';

export default function System() {
    return (
        <div className='system-page'>
            <h1>System</h1>
        </div>
    );
}
