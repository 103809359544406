/**
 * @file Navigator.tsx
 * @description This file contains the navigator component used to display the navigator in the application.
 * @author Sebastiaan Koedam
 */

import { Link } from 'react-router-dom';
import { useState } from 'react';
import '../styles/components/navigator.css';

/**
 * Navigator component
 * @returns Navigator component
 */
export function Navigator() {
    const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

    const toggleMobileMenu = () => {
        setMobileMenuOpen(prevState => !prevState);
    };

    return (
        <nav>
            <div className='mobile-container'>
                <a href='/' className='logo-container'>
                    <img src='company-logo-white.png' alt='getgood logo' className='logo' />
                    <span className='logo-title'>getgood</span>
                </a>
                <div className={`navigator-container ${isMobileMenuOpen ? 'open' : ''}`}>
                    <NavigatorElement toggleMenu={toggleMobileMenu} title='Features' targetPage='/features' />
                    <NavigatorElement toggleMenu={toggleMobileMenu} title='Invoices' targetPage='/invoices' />
                    <NavigatorElement toggleMenu={toggleMobileMenu} title='Support' targetPage='/support' />
                    <NavigatorElement toggleMenu={toggleMobileMenu} title='System' targetPage='/system' />
                    <NavigatorElement toggleMenu={toggleMobileMenu} title='Users' targetPage='/users' />
                </div>
                <div className='mobile-menu-icon' onClick={toggleMobileMenu}>
                    <span className='bar'></span>
                    <span className='bar'></span>
                    <span className='bar'></span>
                </div>
            </div>
        </nav>
    );
}

/**
 * NavigatorElement component
 * @param title Title of the navigator element
 * @param targetPage Target page of the navigator element
 * @returns NavigatorElement component
 */
function NavigatorElement({
    title,
    targetPage,
    toggleMenu,
}: {
    title: string;
    targetPage: string;
    toggleMenu?: () => void;
}) {
    return (
        <Link to={targetPage} className='navigator-item' onClick={toggleMenu}>
            <span className='--text'>{title}</span>
        </Link>
    );
}
