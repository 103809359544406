/**
 * @file Invoices.tsx
 * @description This file contains not that much for now.
 * @author Sebastiaan Koedam
 */

import '../styles/pages/invoices.css';

export default function Invoices() {
    console.log('Rendering Invoices Component');
    return (
        <div className='invoice-page'>
            <h1>Invoices</h1>
        </div>
    );
}
