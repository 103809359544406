/**
 * @file UseUser.tsx
 * @description Contains custom hooks for managing users: fetching and adding users.
 * `useFetchedUsers` handles fetching user data and managing errors, while `useAddUser` facilitates adding new users.
 * @author Sebastiaan Koedam
 */

import { useState, useEffect } from 'react';
import { fetchUsers, addUser, deleteUser } from '../services/UserService';
import { User } from '../types/UserTypes';

/**
 * Custom hook to fetch and manage users.
 * @returns {Object} An object containing the users array and any error that occurred.
 */
export function useFetchedUsers() {
    const [users, setUsers] = useState<User[]>([]);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        const loadUsers = async () => {
            try {
                const fetchedUsers = await fetchUsers();
                setUsers(fetchedUsers);
            } catch {
                setError('Failed to fetch users.');
            }
        };
        loadUsers();
    }, []);

    const updateUserHandler = async () => {
        try {
            const fetchedUsers = await fetchUsers();
            setUsers(fetchedUsers);
        } catch {
            alert('Failed to update users.');
        }
    };

    return { users, error, updateUserHandler };
}

// Type definition for adding a new user, excluding UserID and Status
type NewUser = Omit<User, 'UserID' | 'Status'>;

/**
 * Custom hook for adding new users.
 * @returns {Object} An object containing a handler function to add users.
 */
export function useAddUser() {
    /**
     * Handler function to add new users.
     * @param {NewUser[]} newUsers Array of new users to add.
     * @throws {Error} If adding users fails.
     */
    const addUsersHandler = async (newUsers: NewUser[]) => {
        try {
            for (const newUser of newUsers) {
                await addUser(newUser);
            }
        } catch {
            throw new Error('Failed to add users.');
        }
    };

    return { addUsersHandler };
}

/**
 * Custom hook for deleting a user.
 * @returns {object} An object containing a handler to delete users.
 */
export function useDeleteUser() {
    const deleteUserHandler = async (UserID: number) => {
        try {
            await deleteUser(UserID);
        } catch {
            throw Error('Failed to delete user');
        }
    };

    return { deleteUserHandler };
}