/**
 * @file Feature.tsx
 * @description This file contains a general fetch function to call the API, 
 * which can be used in multiple components to fetch data from the API.
 * @author Sebastiaan Koedam
 */

/**
 * Fetch data from the API
 * @param endpoint The endpoint to fetch data from
 * @param options The options for the fetch request
 * @returns The data fetched from the API
 * @throws Error if the fetch request fails
 */
export default async function apiFetch<T>(endpoint: string, options: RequestInit = {}): Promise<T> {
    const baseUrl = 'https://control.getgood.nl/api'; // Base URL for the API
    try {
        const response = await fetch(`${baseUrl}/${endpoint}`, {
            // Fetch the data from the API
            ...options,
            headers: {
                'Content-Type': 'application/json',
                ...options.headers,
            },
        });
        if (!response.ok) {
            // If the response is not OK, throw an error
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const result = await response.json(); // Parse the response as JSON
        return result as T; // Return the result as type T so the caller can use it
    } catch (error) {
        throw error;
    }
}
