/**
 * @file AddFeatureCard.tsx
 * @description Contains the `AddFeatureCard` component, used to add new features to the application.
 * It includes functionality for showing a form to input feature details and handling the saving and cancellation of the new feature.
 * @author Sebastiaan Koedam
 */

import { useAddFeature } from '../../hooks/UseFeatures';
import { useState } from 'react';
import '../../styles/components/feature-card.css';

/**
 * AddFeatureCard component
 * @param {Function} update Function to call for updating the list of features after adding a new feature.
 * @returns {JSX.Element} A button to trigger the form for adding a new feature or the form itself.
 */
export function AddFeatureCard({ update }: { update: () => void }) {
    const { addFeatureHandler } = useAddFeature();

    // Local state to manage form visibility and input values
    const [isAdding, setIsAdding] = useState(false);
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [isSaving, setIsSaving] = useState(false); // State to handle saving status

    /**
     * Displays the form for adding a new feature.
     */
    const handleAddClick = () => {
        setIsAdding(true);
    };

    /**
     * Cancels adding a new feature, resets input fields, and hides the form.
     */
    const handleCancel = () => {
        setIsAdding(false);
        setName('');
        setDescription('');
    };

    /**
     * Saves the new feature and handles form visibility and saving status.
     */
    const handleSave = async () => {
        // Ensure all required fields are filled
        if (!name || !description) {
            alert('Please fill in all required fields.');
            return;
        }

        // Prevent multiple save operations
        if (isSaving) {
            return;
        }

        setIsSaving(true);

        try {
            await addFeatureHandler(name, description);
            update(); // Update the feature list
            handleCancel(); // Reset form and hide the add form
        } catch (error) {
            // Handle errors that occur during saving
            alert('Failed to add feature.');
            console.error('Add feature error:', error);
        } finally {
            setIsSaving(false);
        }
    };

    // Render the form for adding a new feature or a button to show the form
    if (isAdding) {
        return (
            <div className='feature-card feature-card-adding'>
                <input
                    type='text'
                    placeholder='Feature Name'
                    value={name}
                    onChange={e => setName(e.target.value)}
                    className='feature-name-input'
                />
                <textarea
                    placeholder='Feature Description'
                    value={description}
                    onChange={e => setDescription(e.target.value)}
                    className='feature-desc-input'
                />
                <div className='feature-actions'>
                    <button 
                        onClick={handleSave} 
                        className='feature-button feature-save' 
                        disabled={isSaving}
                    >
                        Save
                    </button>
                    <button 
                        onClick={handleCancel} 
                        className='feature-button feature-cancel'
                    >
                        Cancel
                    </button>
                </div>
            </div>
        );
    }

    // Render the "Add" button when the form is not visible
    return (
        <div className='feature-card feature-card-add' onClick={handleAddClick}>
            <span className='feature-add-icon'>+</span>
        </div>
    );
}
