/**
 * @file AddUserCard.tsx
 * @description Contains the AddUserCard component, which provides a form for adding multiple users.
 * @author Sebastiaan Koedam
 */

import { useState } from 'react';
import { useAddUser } from '../../hooks/UseUsers';
import '../../styles/components/user-card.css';

export function AddUserCard({
    isVisible,
    onClose,
    update,
}: {
    update: () => void;
    isVisible: boolean;
    onClose?: () => void;
}) {
    const initialState = { Name: '', Email: '', Role: '' };
    const [users, setUsers] = useState([initialState]);
    const { addUsersHandler } = useAddUser();
    const [formStatus, setFormStatus] = useState<string | undefined>(undefined);

    /**
     * Handles changes to input fields in the user form.
     * @param {number} index The index of the user being updated.
     * @param {React.ChangeEvent<HTMLInputElement | HTMLSelectElement>} event The change event.
     */
    const handleInputChange = (index: number, event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value } = event.target;
        const updatedUsers = [...users];
        updatedUsers[index] = { ...updatedUsers[index], [name]: value };
        setUsers(updatedUsers);
    };

    const handleCancel = () => {
        setUsers([initialState]);
    };

    /**
     * Adds a new user input field to the form.
     */
    const addUser = () => {
        setUsers([...users, { Name: '', Email: '', Role: '' }]);
    };

    /**
     * Removes a user input field from the form.
     * @param {number} index The index of the user to remove.
     */
    const removeUser = (index: number) => {
        const updatedUsers = users.filter((_, i) => i !== index);
        setUsers(updatedUsers);
    };

    /**
     * Handles form submission to add users.
     */
    const handleSubmit = async () => {
        for (let user of users) {
            if (!user.Name || !user.Email || !user.Role) {
                alert('Please fill out all required fields.');
                return;
            }
        }
        try {
            await sendMail();
            await addUsersHandler(users);
            update();
        } catch (error) {
            // Log the error or display an error message in the UI
            console.error('Failed to add users:', error);
        }
    };

    const sendMail = async () => {
        setFormStatus('Sending email...');

        for (let user of users) {
        fetch('https://getgood.nl/adduser', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(user.Email)
        })
            .then(response => {
                if (!response.ok) {
                    setFormStatus('Something went wrong while sending the email...');
                    return
                }
                setFormStatus('Message send!');
            })
            .catch(() => setFormStatus('Something went wrong while sending the email...'))
            .finally(() => {
                setTimeout(() => setFormStatus(''), 3000); // Reset the status message after three seconds.
                setUsers([initialState]);
                if (onClose) onClose();
            })
    }};

    // Do not render the component if it is not visible
    if (!isVisible) {
        return null;
    }

    return (
        <div className='overlay'>
            <div className='add-user-form'>
                <h2>Add Users</h2>
                {users.map((user, index) => (
                    <div className='form-group' key={index}>
                        <input
                            className='name-input'
                            type='text'
                            name='Name'
                            placeholder='Name'
                            value={user.Name}
                            onChange={e => handleInputChange(index, e)}
                        />
                        <input
                            className='email-input'
                            type='email'
                            name='Email'
                            placeholder='Email'
                            value={user.Email}
                            onChange={e => handleInputChange(index, e)}
                        />
                        <select name='Role' value={user.Role} onChange={e => handleInputChange(index, e)}>
                            <option value='' disabled hidden>
                                Select a role
                            </option>
                            <option value='admin'>Admin</option>
                            <option value='editor'>Editor</option>
                            <option value='viewer'>Viewer</option>
                        </select>
                        <button className='remove-button' onClick={() => removeUser(index)}>
                            X
                        </button>
                    </div>
                ))}
                <div className='form-status'>
                    <span>{formStatus}</span>
                </div>
                <div className='form-actions'>
                    <button className='add-user-button' onClick={addUser}>
                        Add Another User
                    </button>
                    <button
                        className='cancel-button'
                        onClick={() => {
                            handleCancel();
                            if (onClose) onClose();
                        }}
                    >
                        Cancel
                    </button>

                    <button className='confirm-button' onClick={handleSubmit}>
                        Confirm
                    </button>
                </div>
            </div>
        </div>
    );
}
