/**
 * @file index.tsx
 * @description This file contains the main entry point for the web application.
 * It renders the Navigator and Pages components, which are used to navigate between different pages in the application.
 * @author Sebastiaan Koedam
 */

import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import { Navigator } from './components/Navigator';
import { Pages } from './util/Pages';
import './styles/styles.css';
import { ConfirmationDialogProvider } from './util/ConfirmationDialogContext';

// Select the root element
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

// Render the web app
root.render(
    <ConfirmationDialogProvider>
        <Router>
            <Navigator />
            <div className='h-line'/>
            <div className='page-container'>
                <Pages />
            </div>
        </Router>
    </ConfirmationDialogProvider>,
);