/**
 * @file UserService.tsx
 * @description Contains functions for interacting with the users API: fetching, adding, editing, deleting, and updating users.
 * @author Sebastiaan Koedam
 */

import apiFetch from '../util/FetchApi';
import { User } from '../types/UserTypes';

/**
 * Fetches all users from the API.
 * @returns {Promise<User[]>} A promise that resolves to an array of User objects.
 * @throws {Error} If the request fails.
 */
export async function fetchUsers(): Promise<User[]> {
    try {
        return await apiFetch<User[]>('users');
    } catch {
        throw new Error('Failed to fetch users.');
    }
}

// Omit UserID and Status for new users
type NewUser = Omit<User, 'UserID' | 'Status'>;

/**
 * Adds a new user to the API.
 * @param {NewUser} newUser The user details to add.
 * @returns {Promise<User>} A promise that resolves to the added User object.
 * @throws {Error} If the request fails.
 */
export async function addUser(newUser: NewUser): Promise<User> {
    try {
        return await apiFetch<User>('users/add', {
            method: 'POST',
            body: JSON.stringify(newUser),
            headers: { 'Content-Type': 'application/json' },
        });
    } catch {
        throw new Error('Failed to add user.');
    }
}

/**
 * Deletes a user from the API.
 * @param {number} UserID The unique identifier of the user to delete.
 * @returns {Promise<void>} A promise that resolves when the deletion is complete.
 * @throws {Error} If the request fails or if UserID is invalid.
 */
export async function deleteUser(UserID: number): Promise<void> {
    if (typeof UserID !== 'number' || UserID <= 0) {
        throw new Error('Invalid UserID.');
    }
    try {
        await apiFetch(`users/delete/${UserID}`, {
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json' },
        });
    } catch {
        throw new Error('Failed to delete user.');
    }
}