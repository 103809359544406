/**
 * @file Cards.tsx
 * @description This file contains the Card component, which is used to display cards with a title, description, and a target page.
 * @author Sebastiaan Koedam
 */

import { Link } from 'react-router-dom';
import '../styles/components/card.css';

/**
 * Card component
 * @param title Title of the card
 * @param desc Description of the card
 * @param targetPage Target page of the card
 * @returns Card component
 */
export function Card({ title, desc, targetPage }: { title: string; desc: string; targetPage: string }) {
    return (
        <Link to={targetPage}>
            <div className='card'>
                <div className='card-content'>
                    <h2 className='card-title'>{title}</h2>
                    <p className='card-desc'>{desc}</p>
                </div>
            </div>
        </Link>
    );
}
