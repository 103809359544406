/**
 * @file ConfirmationDialogContext.tsx
 * @description This file contains the `ConfirmationDialogContext`,
 * which provides a way to manage and display a confirmation dialog across the application.
 * It includes the `ConfirmationDialogProvider` component and the
 * `useConfirmationDialog` hook to handle the dialog's visibility and actions.
 * @author Sebastiaan Koedam
 */

import React, { createContext, useState, useContext, ReactNode } from 'react';
import ConfirmationDialog from '../components/ConfirmationDialog';

// Interface for the context value, providing functions to control the confirmation dialog
interface ConfirmationDialogContextProps {
    showDialog: (message: string, onConfirm: () => void) => void;
    hideDialog: () => void;
}

// Create a context for managing confirmation dialogs
const ConfirmationDialogContext = createContext<ConfirmationDialogContextProps | undefined>(undefined);

// Custom hook to use the confirmation dialog context
export const useConfirmationDialog = () => {
    const context = useContext(ConfirmationDialogContext);
    if (!context) {
        throw new Error('useConfirmationDialog must be used within a ConfirmationDialogProvider');
    }
    return context;
};

// Provider component that wraps the part of the app needing confirmation dialogs
export const ConfirmationDialogProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [isVisible, setIsVisible] = useState(false);
    const [message, setMessage] = useState('');
    const [onConfirm, setOnConfirm] = useState<() => void>(() => () => {});

    // Function to show the confirmation dialog
    const showDialog = (message: string, onConfirm: () => void) => {
        setMessage(message);
        setOnConfirm(() => onConfirm);
        setIsVisible(true);
    };

    // Function to hide the confirmation dialog
    const hideDialog = () => {
        setIsVisible(false);
    };

    // Function to handle the confirm action
    const handleConfirm = () => {
        onConfirm();
        hideDialog();
    };

    return (
        <ConfirmationDialogContext.Provider value={{ showDialog, hideDialog }}>
            {children}
            <ConfirmationDialog
                isVisible={isVisible}
                message={message}
                onConfirm={handleConfirm}
                onCancel={hideDialog}
            />
        </ConfirmationDialogContext.Provider>
    );
};
