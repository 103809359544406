/**
 * @file Feature.tsx
 * @description This file contains all the routes for the web app, 
 * which are used to navigate between the different pages.
 * @author Sebastiaan Koedam
 */

import { Route, Routes } from 'react-router-dom';
import Features from '../pages/Features';
import Home from '../pages/Home';
import Invoices from '../pages/Invoices';
import Support from '../pages/Support';
import System from '../pages/System';
import Users from '../pages/Users';

/**
 * Pages component
 * @returns Routes component with all the routes for the web app
 */
export function Pages() {
    return (
        <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/features' element={<Features />} />
            <Route path='/invoices' element={<Invoices />} />
            <Route path='/support' element={<Support />} />
            <Route path='/system' element={<System />} />
            <Route path='/users' element={<Users />} />
        </Routes>
    );
}
