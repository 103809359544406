/**
 * @file Support.tsx
 * @description This file contains not that much for now.
 * @author Sebastiaan Koedam
 */

import '../styles/pages/support.css';

export default function Support() {
    return (
        <div className='support-page'>
            <h1>Support</h1>
        </div>
    );
}
