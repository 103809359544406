/**
 * @file Home.tsx
 * @description This file contains the Home page of the web app,
 * which displays the main features of the application and provides links to navigate to the different pages.
 * @author Sebastiaan Koedam
 */

import { Card } from '../components/Cards';
import '../styles/pages/home.css';

/**
 * Home component
 * @returns TSX.Element
 */
export default function Home() {
    return (
        <div className='home-page'>
            <Card
                title='Features'
                desc='Manage and customize all the features of the application, including settings, functionalities, and user preferences.'
                targetPage='/features'
            />
            <Card
                title='Invoices'
                desc='Access and manage the invoice system, including generating, editing, and tracking invoices, and handling payment statuses and billing details.'
                targetPage='/invoices'
            />
            <Card
                title='Support'
                desc='Oversee the support system, including managing customer inquiries, handling support tickets, tracking resolutions, and accessing support resources.'
                targetPage='/support'
            />
            <Card
                title='System'
                desc='Monitor and review the health and performance of the system, including checking system status, performance metrics, and error logs to ensure smooth operation.'
                targetPage='/system'
            />
            <Card
                title='Users'
                desc='Manage all user-related tasks, including creating and updating user profiles, setting permissions, and monitoring user activity and access.'
                targetPage='/users'
            />
        </div>
    );
}
