/**
 * @file ConfirmationDialog.tsx
 * @description This file contains the `ConfirmationDialog` component, 
 * which displays a modal dialog for user confirmation with a message and two buttons: Confirm and Cancel.
 * The dialog is used to request user confirmation before performing actions that might have significant consequences.
 * @author Sebastiaan Koedam
 */

import '../styles/components/confirmation-dialog.css';

// Props interface for the ConfirmationDialog component
interface ConfirmationDialogProps {
    isVisible: boolean; 
    message: string;
    onConfirm: () => void;
    onCancel: () => void; 
}

// ConfirmationDialog component
const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({ isVisible, message, onConfirm, onCancel }) => {
    // If the dialog is not visible, return null to avoid rendering the dialog
    if (!isVisible) return null;

    return (
        <div className='confirmation-dialog-overlay'>
            <div className='confirmation-dialog-content'>
                <span>{message}</span>
                <div className='confirmation-dialog-buttons'>
                    <button className='dialog-confirm' onClick={onConfirm}>Confirm</button>
                    <button className='dialog-cancel' onClick={onCancel}>Cancel</button>
                </div>
            </div>
        </div>
    );
};

export default ConfirmationDialog;
