/**
 * @file UserCard.tsx
 * @description This file contains the UserCard component, which is used to display and manage users in the application.
 * @author Sebastiaan Koedam
 */

import '../../styles/components/user-card.css';
import { User } from '../../types/UserTypes';
import { useConfirmationDialog } from '../../util/ConfirmationDialogContext';
import { useDeleteUser } from '../../hooks/UseUsers';
import { Icons } from '../../components/Icons';

/**
 * UserCard component
 * @returns UserCard component
 */
export function UserCard({ user, update }: { user: User; update: () => void }) {
    const { showDialog } = useConfirmationDialog();
    const { deleteUserHandler } = useDeleteUser();

    // Determine CSS class based on user status same for role
    const statusClass = 
        user.Status === 'Active' ? 'user-status-active' :
        user.Status === 'Pending' ? 'user-status-pending' :
        'user-status-disabled';

    const roleClass = 
        user.Role === 'Admin' ? 'user-role-admin' :
        user.Role === 'Editor' ? 'user-role-editor' :
        'user-role-viewer';

    /**
     * Handles the deletion of a user.
     */
    const handleDelete = async () => {
        showDialog('Are you sure you want to perform this action?', async () => {
            try {
                await deleteUserHandler(user.UserID);
                update();
            } catch (error) {
                alert('Failed to delete user.');
            }
        });
    };

    return (
        <div className='user'>
            <div className='user-info'>
                <h3 className='user-name'>{user.Name}</h3>
                <p className='user-email'>{user.Email}</p>
            </div>
            <p className={`user-role ${roleClass}`}>{user.Role}</p>
            <p className={`user-status ${statusClass}`}>{user.Status}</p>
            <div className='delete-user'>
                <button onClick={handleDelete}>{Icons.Bin}Delete</button>
            </div>
        </div>
    );
}
