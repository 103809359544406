/**
 * @file Users.tsx
 * @description Displays a list of users and provides an option to add new users.
 * @author Sebastiaan Koedam
 */

import { useState } from 'react';
import { UserCard } from '../components/users/UserCard';
import { AddUserCard } from '../components/users/AddUserCard';
import { useFetchedUsers } from '../hooks/UseUsers';
import '../styles/pages/users.css';

export default function Users() {
    const { users, error, updateUserHandler } = useFetchedUsers();
    const [showAddUserCard, setShowAddUserCard] = useState(false);

    // Display error message if fetching users fails
    if (error) return <div>{error}</div>;

    return (
        <div className='user-page'>
            <div className='page-top'>
                <h1>Users</h1>
                <button onClick={() => setShowAddUserCard(true)}>Add new user</button>
            </div>
            <div className='page-dashboard'>
                <div className='dashboard-top'>
                    <h2 className='top-name'>Name</h2>
                    <h2 className='top-role'>Role</h2>
                    <h2 className='top-status'>Status</h2>
                    <h2 className='top-action'>Action</h2>
                </div>
                <div className='user-overview'>
                    {users.map(user => (
                        <UserCard key={user.UserID} user={user} update={updateUserHandler}/>
                    ))}
                </div>
            </div>
            <AddUserCard isVisible={showAddUserCard} onClose={() => setShowAddUserCard(false)} update={updateUserHandler}/>
        </div>
    );
}
