/**
 * @file UseFeature.tsx
 * @description Contains custom hooks for managing features in the application.
 * Hooks include fetching, updating, editing, deleting, and adding features.
 * @author Sebastiaan Koedam
 */

import { useState, useEffect } from 'react';
import { fetchFeatures, statusFeature, editFeature, deleteFeature, addFeature } from '../services/FeatureService';
import { Feature } from '../types/FeatureTypes';

/**
 * Custom hook for managing feature data.
 * @returns {Object} An object containing:
 * - `features`: The current list of features.
 * - `error`: Any error message from fetching or updating features.
 * - `updateFeatureHandler`: Function to refresh the list of features.
 */
export function useFeatures() {
    const [features, setFeatures] = useState<Feature[]>([]);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        const loadFeatures = async () => {
            try {
                const fetchedFeatures = await fetchFeatures();
                setFeatures(fetchedFeatures);
            } catch {
                setError('Failed to fetch features.');
            }
        };
        loadFeatures();
    }, []);

    const updateFeatureHandler = async () => {
        try {
            const fetchedFeatures = await fetchFeatures();
            setFeatures(fetchedFeatures);
        } catch {
            alert('Failed to update features.');
        }
    };

    return { features, error, updateFeatureHandler };
}

/**
 * Custom hook for updating feature status.
 * @returns {Object} An object containing:
 * - `statusFeatureHandler`: Function to update the status of a feature.
 */
export function useStatusFeature() {
    const statusFeatureHandler = async (FeatureID: number, Status: string): Promise<void> => {
        try {
            await statusFeature(FeatureID, Status);
        } catch {
            alert('Failed to update feature status.');
        }
    };

    return { statusFeatureHandler };
}

/**
 * Custom hook for editing a feature.
 * @returns {Object} An object containing:
 * - `editFeatureHandler`: Function to edit a feature's details.
 */
export function useEditFeature() {
    const editFeatureHandler = async (FeatureID: number, Name: string, Description: string) => {
        try {
            await editFeature(FeatureID, Name, Description);
        } catch {
            alert('Failed to edit feature.');
        }
    };

    return { editFeatureHandler };
}

/**
 * Custom hook for deleting a feature.
 * @returns {Object} An object containing:
 * - `deleteFeatureHandler`: Function to delete a feature.
 */
export function useDeleteFeature() {
    const deleteFeatureHandler = async (FeatureID: number): Promise<void> => {
        try {
            await deleteFeature(FeatureID);
        } catch {
            alert('Failed to delete feature.');
        }
    };

    return { deleteFeatureHandler };
}

/**
 * Custom hook for adding a new feature.
 * @returns {Object} An object containing:
 * - `addFeatureHandler`: Function to add a new feature.
 */
export function useAddFeature() {
    const addFeatureHandler = async (Name: string, Description: string) => {
        try {
            await addFeature(Name, Description);
        } catch {
            alert('Failed to add feature.');
        }
    };

    return { addFeatureHandler };
}
