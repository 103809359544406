/**
 * @file FeatureCard.tsx
 * @description Contains the `FeatureCard` and `AddFeatureCard` components. 
 * `FeatureCard` displays and manages features with options to toggle status, edit, and delete, while `AddFeatureCard` is used to add new features.
 * @author Sebastiaan Koedam
 */

import { Feature } from '../../types/FeatureTypes';
import { useStatusFeature, useDeleteFeature, useEditFeature } from '../../hooks/UseFeatures';
import { useState } from 'react';
import { useConfirmationDialog } from '../../util/ConfirmationDialogContext';
import '../../styles/components/feature-card.css';

/**
 * FeatureCard component
 * @param {Feature} feature The feature object to be displayed and managed.
 * @param {Function} update Function to call for updating the list of features after actions like delete or status toggle.
 * @returns {JSX.Element} A feature card with options to toggle status, edit, or delete the feature.
 */
export function FeatureCard({ feature, update }: { feature: Feature; update: () => void }) {
    const { statusFeatureHandler } = useStatusFeature();
    const { deleteFeatureHandler } = useDeleteFeature();
    const { editFeatureHandler } = useEditFeature();
    const { showDialog } = useConfirmationDialog();

    // Determine CSS class based on feature status
    const statusClass = feature.Status === 'Enabled' ? 'status-enabled' : 'status-disabled';

    /**
     * Toggles the status of the feature and updates the list.
     */
    const handleStatus = async () => {
        showDialog('Are you sure you want to perform this action?', async () => {
            const newStatus = feature.Status === 'Enabled' ? 'Disabled' : 'Enabled';
            try {
                await statusFeatureHandler(feature.FeatureID, newStatus);
                update();
            } catch (error) {
                // Handle the error (e.g., display an error message in the UI)
                console.error('Failed to toggle feature status:', error);
            }
        });
    };

    // Local state for managing the visibility of the edit form and its input values
    const [isEditing, setIsEditing] = useState(false);
    const [editName, setEditName] = useState(feature.Name);
    const [editDescription, setEditDescription] = useState(feature.Description);

    /**
     * Handles editing of the feature. If in edit mode, saves changes; otherwise, toggles edit mode.
     */
    const handleEdit = async () => {
        if (isEditing) {
            if (!editName || !editDescription) {
                alert('Please fill in all required fields.');
                return;
            }
            try {
                await editFeatureHandler(feature.FeatureID, editName, editDescription);
                update();
            } catch (error) {
                alert('Failed to edit feature.');
                console.error('Edit feature error:', error);
            } finally {
                setIsEditing(false);
            }
        } else {
            setIsEditing(true);
        }
    };

    /**
     * Handles the deletion of the feature.
     */
    const handleDelete = async () => {
        showDialog('Are you sure you want to perform this action?', async () => {
            try {
                await deleteFeatureHandler(feature.FeatureID);
                update();
            } catch (error) {
                alert('Failed to delete feature.');
                console.error('Delete feature error:', error);
            }
        });
    };

    // Render the feature card with options for editing, toggling status, and deleting
    return (
        <div className={`feature-card ${isEditing ? 'feature-card-editing' : ''}`}>
            {isEditing ? (
                <div className='feature-card-edit'>
                    <input
                        type='text'
                        value={editName}
                        onChange={e => setEditName(e.target.value)}
                        className='feature-name-input'
                    />
                    <textarea
                        value={editDescription}
                        onChange={e => setEditDescription(e.target.value)}
                        className='feature-desc-input'
                    />
                    <div className='feature-actions'>
                        <button onClick={handleEdit} className='feature-button feature-save'>
                            Save
                        </button>
                        <button onClick={() => setIsEditing(false)} className='feature-button feature-cancel'>
                            Cancel
                        </button>
                    </div>
                </div>
            ) : (
                <>
                    <h2 className='feature-name'>{feature.Name}</h2>
                    <p className='feature-desc'>{feature.Description}</p>
                    <div className='feature-buttons'>
                        <button onClick={handleStatus} className={`feature-button feature-status ${statusClass}`}>
                            {feature.Status}
                        </button>
                        <button onClick={handleEdit} className='feature-button feature-edit'>
                            {isEditing ? 'Cancel' : 'Edit'}
                        </button>
                        <button onClick={handleDelete} className='feature-button feature-delete'>
                            Delete
                        </button>
                    </div>
                </>
            )}
        </div>
    );
}
