/**
 * @file Feature.tsx
 * @description Contains the Features component, which displays, manages, and adds features in the application.
 * Utilizes the FeatureCard and AddFeatureCard components and interacts with the custom useFeatures hook.
 * @author Sebastiaan Koedam
 */

import { FeatureCard } from '../components/features/FeatureCard';
import { AddFeatureCard } from '../components/features/AddFeatureCard';
import { useFeatures } from '../hooks/UseFeatures';
import '../styles/pages/features.css';

/**
 * Features component
 * @returns {JSX.Element} The rendered Features component.
 * @description Renders the main features page with a list of feature cards and an interface for adding new features.
 */
export default function Features() {
    const { features, error, updateFeatureHandler } = useFeatures();

    // Display error message if fetching features fails
    if (error) return <div>{error}</div>;

    return (
        <div className='feature-page'>
            <h1>Features</h1>
            <div className='feature-page-cards'>
                {features.map(feature => (
                    <FeatureCard key={feature.FeatureID} feature={feature} update={updateFeatureHandler} />
                ))}
                <AddFeatureCard update={updateFeatureHandler} />
            </div>
        </div>
    );
}
